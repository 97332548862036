
export const CWhite='#F3F1F1'
//'#EBE7E8'
export const CBeige='#DAD2D3'
//'#AE9B9D'
export const CGray='#4B4E5F'
export const CNavy='#1A2636'
export const CBlack='#1C1D22'
export const CRed='#DD4800'
export const CBrown='#9D463D'

export const CLOrange='#f9c00c'
export const CLblue='#00b9f1'
export const CLPerple='#7200da'
export const CLRed='#f9320c'

export const fontStyle={
    fontFamily: '"Yu Mincho", "Merriweather", "Georgia", Cambria, "Times New Roman"',
}